import React from "react";
import { Facebook, Room, WhatsApp, Phone, Mail, KeyboardArrowUp } from "@material-ui/icons"
import { Row, Col, Button } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll';
import { ScrollTo } from "react-scroll-to";

const Footer = () => {
  return (
      <div style={{ backgroundColor: "#2F2F31", color: "silver", textAlign: "center", borderTop: "5px solid teal" }}>
        <div
          style={{
            borderBottom: "1px solid rgba(255,255,255,0.5)",
            paddingTop: "50px",
            paddingRight: "30px",
            paddingLeft: "30px",
            paddingBottom: "30px"
          }}
        >
          Al Quran is an online quran academy for kids and adults on skype. we provide one on one quran lessons by real tutors on skype. We are available 24/7. We offer first week free trial lessons. register to start free trials right now.
        </div>
        <br />
        <div style={{ color: "silver" }}>
          Copyright © 2021-2022 Al Quran
        </div>
        
        <ScrollTo>
          {({ scroll }) => (
            <Button variant="default" 
              style={{
                background: "silver", 
                position: "absolute",
                right: "50px"
              }}
              className="foo-btn"
              onClick={() => scroll({ x: 0, y: 0, smooth: true })}
            >
              <KeyboardArrowUp/>
            </Button>
          )}
        </ScrollTo>
        <br/>
        <br/>
      </div>
  );
};

export default Footer;
