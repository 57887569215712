import React,{Component} from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Loading from 'react-fullscreen-loading';
import ScrollAnimation from 'react-animate-on-scroll';
import { Table } from "react-bootstrap";

const weekDaysArr = [
  {
    id: 1,
    fees: 39,
    days: 2,
    duration: 30,
    lessons: 8
  },
  {
    id: 2,
    fees: 45,
    days: 3,
    duration: 30,
    lessons: 12
  },
  {
    id: 3,
    fees: 59,
    days: 4,
    duration: 30,
    lessons: 16
  },
  {
    id: 4,
    fees: 69,
    days: 5,
    duration: 30,
    lessons: 20
  }
]

const weekendArr = [
  {
    id: 1,
    fees: 23,
    days: 2,
    duration: 30,
    lessons: 8
  },
  {
    id: 2,
    fees: 35,
    days: 2,
    duration: 45,
    lessons: 8
  }
]

class Fees extends Component {

  state = { flag: false }
  
  componentDidMount(){
    setTimeout(() => this.setState({ flag: true }),
                600)
  }

  render() {
    if (this.state.flag){
      return (
        <div>
          <Navbar />
          <div
            style={{
              paddingTop: "100px",
              paddingLeft: "50px",
              paddingRight: "50px",
              marginTop: "-15px"
            }}
          >
            <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
            <div style={{ color: "rgb(15, 131, 131)" }}>
              <h1 style={{ fontWeight: "bolder", fontSize: "70px" }}>
                Fees
              </h1>
            </div>
            </ScrollAnimation>
          </div>
          <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
          <div style={{ padding: "30px", marginBottom: "100px", marginLeft: "20px" }}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th colSpan={5}>
                    <h1 style={{ fontWeight: "bolder", fontSize: "24px", color: "rgb(15, 131, 131)" }}>
                      Packages for Weekdays Lessons
                    </h1>
                  </th>
                </tr>
                <tr>
                  <th>#</th>
                  <th>Fees Per Month</th>
                  <th>Days Per Week</th>
                  <th>Duration</th>
                  <th>Lessons per month</th>
                </tr>
              </thead>
              <tbody>
                {weekDaysArr.map(item => 
                  <tr id={item.id}>
                    <td>{item.id}</td>
                    <td>${item.fees} / Month</td>
                    <td>{item.days} Days / Week</td>
                    <td>{item.duration} Minutes Each Lesson</td>
                    <td>{item.lessons} Lessons / Month</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <br/>
          
            <Table striped bordered hover size="sm">
              
              <thead>
                <th colSpan={5}>
                  <h1 style={{ fontWeight: "bolder", fontSize: "24px", color: "rgb(15, 131, 131)" }}>
                    Packages for Weekend Lessons
                  </h1>
                </th>
    
                <tr>
                  <th>#</th>
                  <th>Fees</th>
                  <th>Days</th>
                  <th>Duration</th>
                  <th>Lessons</th>
                </tr>
              </thead>
              <tbody>
                {weekendArr.map(item => 
                  <tr id={item.id}>
                    <td>{item.id}</td>
                    <td>${item.fees} / Month</td>
                    <td>{item.days} Days / Week</td>
                    <td>{item.duration} Minutes Each Lesson</td>
                    <td>{item.lessons} Lessons / Month</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          </ScrollAnimation>
          <div style={{ marginTop: "-21px" }}>
            <Footer />
          </div>
        </div>
      );
    } else {
      return <Loading loading background="#eee" loaderColor="rgb(15,131,131)" />
    }
  }
};

export default Fees;
