import React, { Component } from "react";
import "./style/style.css";
import Home from "./components/Home/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "tachyons";
import Courses from "./components/Courses/Courses";
import Fees from "./components/Fees/Fees";
import NotFound from "./components/Not Found/NotFound";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import 'react-awesome-slider/dist/styles.css';
import "animate.css/animate.min.css";
import EmploymentAdvice from "./components/Contact/Contact";

class App extends Component {
  render() {
    return (
      <div style={{overflowX: "hidden"}}>
        <BrowserRouter >
          <Switch>
              <Route path="/" component={Home} exact />
              <Route path="/courses" component={Courses} exact />
              <Route path="/fees" component={Fees} exact />
              <Route path="/contact" component={EmploymentAdvice} exact />
              <Route path="/" component={NotFound} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
