import React,{Component} from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Loading from 'react-fullscreen-loading';
import ScrollAnimation from 'react-animate-on-scroll';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import { ExpandMore } from '@material-ui/icons'

class Courses extends Component {

  state = { flag: false }
  
  componentDidMount(){
    setTimeout(() => this.setState({ flag: true }),
                600)
  }

  render() {
    if (this.state.flag){
      return (
        <div>
          <Navbar />
          <div
            style={{
              paddingTop: "100px",
              paddingLeft: "50px",
              paddingRight: "50px",
              marginTop: "-15px"
            }}
          >
            <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
            <div style={{ color: "rgb(15, 131, 131)" }}>
              <h1 style={{ fontWeight: "bolder", fontSize: "70px" }}>
                Courses
              </h1>
            </div>
            </ScrollAnimation>
          </div>
          <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
          <div style={{ padding: "30px", marginBottom: "100px", marginLeft: "20px" }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>For beginners: Quran reading with Tajweed</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <h6 style={{fontWeight: "bold"}}>Eligibility:</h6>
                  <div>
                    This course is designed for beginners who wish to understand more about Islam and learn about the Quran. Whether you have no knowledge of Arabic whatsoever, or you only know the basics, this course is perfect for you.
                  </div>
                  <br/>
                  <h6 style={{fontWeight: "bold"}}>Objectives:</h6>
                  <div style={{marginLeft: 40}}>
                    <ul>
                      <li>Identify the 28 alphabets in the Arabic language</li>
                      <li>Understand how to apply the vowel signs to Arabic letters</li>
                      <li>Learn how to pronounce Arabic words and sentences</li>
                      <li>Determine how to recite Arabic verses in a flowing manner</li>
                      <li>Grasp basic Islamic principles</li>
                      <li>Read the Holy Quran while applying Tajweed rules</li>
                      <li>Memorize short surahs, duas, and kalimas</li>
                    </ul>
                  </div>
                  
                  <h6 style={{fontWeight: "bold"}}>Features:</h6>
                  <div>
                    Learn Quran online from the comfort of your home, assisted by the internet and a phone or computer. This online Quran academy has professional tutors that will introduce you to the basics of Arabic. Firstly, you will be familiarized with the alphabet. Next, you will progress towards reading Arabic sentences. The eventual aim will be to commence recital of the Quran with the correct pronunciation methods (Tajweed). Along the way, you will be taught basic Islamic principles.
                    <br/>
                    <br/>
                    Your online Quran tutor will interact with you closely. He or she will provide guidance that best suits your requirements. The teacher will enable you to progress at a speed that suits your learning ability. A variety of tips and valuable guidelines will help you to master the objectives in a short space of time.
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Quran Memorization and Tafseer</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <h6 style={{fontWeight: "bold"}}>Eligibility:</h6>
                  <div>
                    This course is best for those who already have a reasonable grasp of reading the Arabic language. Some background with Tajweed rules (pronunciation) and understanding of how to recite the Quran will go a long way towards simplifying things for learners.
                  </div>
                  <br/>
                  <h6 style={{fontWeight: "bold"}}>Objectives:</h6>
                  <div style={{marginLeft: 40}}>
                    <ul>
                      <li>Memorize the Holy Quran by heart</li>
                      <li>Develop a deep understanding of the Tajweed rules</li>
                      <li>Mastery of Quranic recitation (Qirat)</li>
                      <li>Revision methodology</li>
                    </ul>
                  </div>
                  
                  <h6 style={{fontWeight: "bold"}}>Features:</h6>
                  <div>
                    This is the ideal course for learning Quran for kids. The best age for learning Quran is during one’s childhood, as that is the optimal age for memorization. However, if you’re an adult and you want to learn too, don’t let that stop you. Quran memorization is one of the most beneficial acts a Muslim can do. As a hafiz (memorizer of the Quran), you will be well placed to further the cause of Islam in the future.
                    <br/><br/>
                    We have qualified online Quran teachers who are waiting to assist you with the best available techniques and methods for memorizing the Holy Book. With lots of prior expertise, our qualified tutors are in prime position to help you in your quest to memorize the Glorious Quran.
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Quran Translation:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <h6 style={{fontWeight: "bold"}}>Eligibility:</h6>
                  <div>
                    This course is designed for learners who are already accustomed to reading the Quran and have an understanding of the Arabic alphabet. Consider taking the beginners’ course if you need to learn Quran reading with Tajweed.
                  </div>
                  <br/>
                  <h6 style={{fontWeight: "bold"}}>Objectives:</h6>
                  <div style={{marginLeft: 40}}>
                    <ul>
                      <li>Learn word by word translation of the Holy Quran</li>
                      <li>Understand the meanings of the Quran</li>
                      <li>Explanation of the Holy Book</li>
                      <li>Diploma course</li>
                    </ul>
                  </div>
                  <h6 style={{fontWeight: "bold"}}>Features:</h6>
                  <div>
                    These online Quran classes are designed to develop readers’ understanding of the Holy Quran. Learning how to read the Quran is an excellent start, but the primary objective for a Muslim has to be to comprehend the meanings of the Holy Book to implement them in our daily lives. This course will enable you to understand the stories narrated in the Quran, Allah’s commands for humankind, and much more.
                    <br/>
                    <br/>
                    Our online Quran lessons for translation are conducted by experts in the field. Not only will the course enable you to comprehend the meaning of the Quran, but you will also gain more in-depth insight into the intricacies of the Arabic language. The method of learning that we follow is a well-established one in this field and has been devised by famous Islamic scholars.
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Tajweed and Tarteel Course:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <h6 style={{fontWeight: "bold"}}>Eligibility:</h6>
                  <div>
                    This course is designed for learners who are already accustomed to reading the Quran and have an understanding of the Arabic alphabet. Consider taking the beginners’ course if you need to learn Quran reading with Tajweed.
                  </div>
                  <br/>
                  <h6 style={{fontWeight: "bold"}}>Objectives:</h6>
                  <div style={{marginLeft: 40}}>
                    <ul>
                      <li>Apply the correct rules of pronunciation during Quran recital</li>
                      <li>Understand the appropriate rhythmic tones for recitation</li>
                      <li>Beautify your recital of the Holy Book</li>
                      <li>Perfect your recitation of the Glorious Quran</li>
                    </ul>
                  </div>
                  <h6 style={{fontWeight: "bold"}}>Features:</h6>
                  <div>
                    Anyone who wishes to recite the Quran as it is meant to be recited must study and implement the rules of pronunciation (Tajweed). This will enable you to pronounce each letter clearly from its correct articulation point. It will also teach you how to respect the characteristics of the Arabic letters. The word tarteel is from the Quran and refers to ‘measured recitation.’ This is a must for anyone who desires to read the Quran in the same way as the Prophet (PBUH).
                    <br/><br/>
                    Our online Quran academy has a skillful group of educators who can help you to master these aspects. They will teach you the basics and show you how to develop towards the advanced levels. Eventually, your instructor will teach you how to read the Quran with the perfect method of recitation.
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Basic Islamic education and Daily Supplications (duas)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <h6 style={{fontWeight: "bold"}}>Eligibility:</h6>
                  <div>
                    This course is designed for kids and beginners who are just starting to get into the world of Quran reading and recital. The program will enable you to understand some of the basics regarding Islam.
                  </div>
                  <br/>
                  <h6 style={{fontWeight: "bold"}}>Objectives:</h6>
                  <div style={{marginLeft: 40}}>
                    <ul>
                      <li>Study the basics about Islam including the five pillars</li>
                      <li>Learn daily supplications</li>
                      <li>Get to know the six kalimas</li>
                      <li>Memorize short surahs</li>
                    </ul>
                  </div>
                  <h6 style={{fontWeight: "bold"}}>Features:</h6>
                  <div>
                    Our courses are not only about learning Quran online, but they also provide an opportunity for kids or newcomers to the religion to get to know some of the basics about Islam. You will learn about the five pillars: Testimony of faith (Tawheed), Prayer (Salah/Namaz), Alms (Zakat), Fasting (Sawm), and Pilgrimage (Hajj). You will also be familiarized with daily duas that are recited upon doing many different acts. Memorization of the short surahs from the 30th chapter of the Quran will be encouraged.
                    <br/><br/>
                    Our online Quran class will have the best tutors who have a wealth of experience with teaching the fundamentals of Islam. Your online Quran tutor will teach you how to develop your reading of the Quran and offer tips on memorization. These are all critical issues that a young or new Muslim must focus on to practice Islam.
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography>Arabic Course:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <h6 style={{fontWeight: "bold"}}>Eligibility:</h6>
                  <div>
                    This course is designed for beginners who wish to understand more about Islam and learn about the Quran. Whether you have no knowledge of Arabic whatsoever, or you only know the basics, this course is perfect for you.
                  </div>
                  <br/>
                  <h6 style={{fontWeight: "bold"}}>Objectives:</h6>
                  <div style={{marginLeft: 40}}>
                    <ul>
                      <li>Study the Arabic alphabets</li>
                      <li>Learn Arabic and Quranic grammar rules</li>
                      <li>Learn Arabic and Quranic vocabulary</li>
                      <li>Understand how to read the Quran</li>
                      <li>Formulate sentences in Arabic</li>
                    </ul>
                  </div>
                  <h6 style={{fontWeight: "bold"}}>Features:</h6>
                  <div>
                    The course will make you familiar with the Arabic language in no time. The Quran was revealed by Allah in Arabic, and to have a correct understanding of the Holy Book, you must learn the language. This program will begin with the basics and the fundamentals of Arabic. From there, you will progress to more advanced levels until you can construct sentences in the Arabic language.
                    <br/><br/>
                    Our online Quran teachers have a wealth of knowledge in this field. We have experts who are accustomed to the many rules of the Arabic language. They have the know-how to impart knowledge to you clearly and concisely. Your queries will receive prompt answers, and engaging lessons will enable you to progress during the course at an appropriate speed.
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          </ScrollAnimation>
          <div style={{ marginTop: "-21px" }}>
            <Footer />
          </div>
        </div>
      );
    } else {
      return <Loading loading background="#eee" loaderColor="rgb(15,131,131)" />
    }
  }
};

export default Courses;
