import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import logo from "../../images/logo2.png";
import { slide as Menu } from "react-burger-menu";
import { Phone, Facebook, WhatsApp, Mail, ArrowDropDown,  } from "@material-ui/icons";


class Navbar extends Component {
  state = {
    isDisplay: false
  }
  render() {
    return (
      <div>
        <div 
          style={{
            backgroundColor: "rgb(15, 131, 131)", 
            position: "fixed", right: 0, 
            left: 0, zIndex: 4, color: "white", 
            fontSize: "12px", paddingLeft: "30px"
          }}
        >
          <a href="tel:(+92)3003217890" style={{color: "white", paddingLeft: "40px"}} className="start-bar">
            <Phone style={{fontSize: "18px"}}/>
            <span style={{marginLeft: "5px"}}>(+92)300 3217890</span>
          </a>
          <span style={{float: "right", marginRight: "30px"}}>
            <a href="https://api.whatsapp.com/send?phone=+923003217890&text=" 
              target="_blank" 
              rel="noopener noreferrer"
              style={{marginLeft: "10px"}}
            >
              <WhatsApp style={{color: "white",fontSize: "18px"}} className="whatsapp"/>
            </a>
            <a href="mailto:abghaffarabbasi@gmail.com" 
              style={{marginLeft: "10px"}}
            >
              <Mail style={{color: "white",fontSize: "18px"}} className="whatsapp"/>
            </a>
          </span>
        </div>
        <div className="navbar">
          <Menu style={{ position: "absolute" }} right={true}>
            <NavLink
              to="/"
              style={{
                textDecoration: "none",
                color: "white",
                paddingLeft:   "10px",
              }}
              activeStyle={{
                backgroundColor: this.props.location.pathname === "/" ? "rgb(15, 131, 131)" : "none",
                paddingTop: this.props.location.pathname === "/" ?"20px":"0px"
              }}
            >
              Home
            </NavLink>
            <NavLink
              to="/courses"
              style={{ textDecoration: "none", color: "white", paddingLeft: "10px" }}
              activeStyle={{ 
                backgroundColor: "rgb(15, 131, 131)",
                paddingTop: "20px"
              }}
            >
              Courses
            </NavLink>
              
                
            <NavLink
              to="/Fees"
              style={{ textDecoration: "none", color: "white",paddingLeft: "20px", width: "100%", backgroundColor: "transparent" }}
              activeStyle={{ 
                backgroundColor: this.props.location.pathname.split("/")[this.props.location.pathname.split("/").length - 1] === "Fees" ? "rgb(15, 131, 131)" : "transparent",
                paddingTop: "20px",
              }}
              className="Fees-menu"
            >
              Fees
            </NavLink>
            
            <NavLink
              to="/Contact"
              style={{ textDecoration: "none", color: "white",paddingLeft: "10px" }}
              activeStyle={{ 
                backgroundColor: "rgb(15, 131, 131)",
                paddingTop: "20px"
              }}
            >
              Contact
            </NavLink>
          </Menu>
          <ul>
            <NavLink
              to="/"
              className="image-link"
            >
              <li>
                <img
                  src={logo}
                  alt="logo"
                  style={{
                    height: "60px",
                    position: "absolute",
                    marginTop: "-18px"
                  }}
                />
              </li>
            </NavLink>
            <NavLink
              to="/"
              style={{
                textDecoration: "none",
                color: "white"
              }}
              activeStyle={{
                marginLeft: "100px",
                backgroundColor: this.props.location.pathname === "/" ? "rgb(15, 131, 131)" : "none",
                paddingBottom: "18px",
                paddingTop: "20px"
              }}
              className="links"
            >
              <li>
                <span>Home</span>
              </li>
            </NavLink>
            <NavLink
              to="/courses"
              style={{ textDecoration: "none", color: "white" }}
              activeStyle={{ 
              backgroundColor: "rgb(15, 131, 131)",
              paddingBottom: "18px",
              paddingTop: "20px"
             }}
             className="links"
            >
              <li>
                <span>Courses</span>
              </li>
            </NavLink>
            <NavLink
              to="/Fees"
              style={{ textDecoration: "none", color: "white" }}
              activeStyle={{ 
                backgroundColor: "rgb(15, 131, 131)",
                paddingBottom: "18px",
                paddingTop: "20px"
              }}
              className="links"
            >
              <li className="Fees-menu">
                <span className="Fees-menu">Fees</span>
              </li>
            </NavLink>
            <NavLink
              to="/Contact"
              style={{ textDecoration: "none", color: "white" }}
              activeStyle={{ 
                backgroundColor: "rgb(15, 131, 131)",
                paddingBottom: "18px",
                paddingTop: "20px"
              }}
              className="links"
            >
              <li>
                <span>Contact</span>
              </li>
            </NavLink>
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(Navbar);
