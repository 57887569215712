import React, { Component } from "react"
import { Container, Card } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import Footer from "../Footer/Footer";
import Loading  from "react-fullscreen-loading";
import Navbar from "../Navbar/Navbar";

class EmploymentAdvice extends Component {
    state = { flag: false }

  componentDidMount(){
    setTimeout(() => this.setState({ flag: true }),
                600)
  }

  render() {
    if (this.state.flag) {
      return (
        <div>
          <Navbar />
          <div className="employAdvice" style={{margin: 0}}>
            <div
              style={{
                paddingTop: "140px",
                color: "rgb(15, 131, 131)"
              }}
            >
              <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
                <Card style={{background: "rgba(15,131,131, 0.8)",textAlign:"center", width: "45%", marginLeft:"auto", marginRight: "auto"}} className="epa">
                  <Card.Body>
                    <h1 style={{ marginBottom: "10px", color: "white", }}>
                      Contact
                    </h1>
                    <div style={{color: "white"}}>
                      You can contact us from following contact details.
                    </div>
                  </Card.Body>
                </Card>
              </ScrollAnimation>
            </div>
          </div>
         
          <div style={{ 
                padding: "50px", 
                paddingTop: "100px",
                paddingBottom: "100px"
              }}
            >
            <ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
            <h1 style={{textAlign: "center", color: "rgb(15,131,131)", borderBottom: "1px solid #eee"}}>
              Contact Us At
            </h1>
            <br/>
            <section>
                <Container>
                <ul className="ul-list">
                    <li><strong>Email:</strong> abghaffarabbasi@gmail.com</li>
                    <li><strong>Phone:</strong> +923003217890</li>
                    <li><strong>Whatsapp:</strong> +923003217890</li>
                    <li><strong>Skype:</strong> karachipakistan890</li>
                </ul>
                </Container>
            </section>
            </ScrollAnimation>
          </div>
          <Footer />
        </div>
      );
    } else {
        return <Loading loading background="#eee" loaderColor="rgb(15,131,131)" />;
      }
    }
}

export default EmploymentAdvice