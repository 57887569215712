import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";


class Quote extends Component {

  render(){
    return (
      <div
        style={{
          paddingLeft: "50px",
          paddingRight: "50px",
        }}
      >
        <br />
        <br />
      <div style={{ marginBottom: 80 }}>
        <h1
          style={{
            background: "white",
            color: "rgb(15, 131, 131)"
          }}
        >
          Al Quran
        </h1>

        <div>
          Al Quran is an online quran academy for kids and adults on skype. we provide one on one quran lessons by real tutors on skype. We are available 24/7. We offer first week free trial lessons. register to start free trials right now.
          
        </div>
         
        <br />
        <Row style={{marginLeft: 100}}>
          <Col>
            <img src={require("../../images/basic.jpg")} alt="image1"/>
            <br/>
            <h5 style={{marginLeft: 30, fontWeight: "bold"}}>Basic Course</h5>
            <br/>
            <ul>
              <li>Basic Alphabet Learning</li>
              <li>letters recognition</li>
              <li>correct pronunciation</li>
              <li>interactive whiteboard sessions</li>
            </ul>
          </Col>
          <Col>
            <img src={require("../../images/prayers.jpg")} alt="image1"/>
            <br/>
            <h5 style={{marginLeft: 10, fontWeight: "bold"}}>Prayers Memorization</h5>
            <br/>
            <ul>
              <li>Memorization of Last small Surahs</li>
              <li>Understanding of Salah (prayer)</li>
              <li>40 short ahadits and 40 Duas</li>
              <li>Other necessary islamic principles</li>
            </ul>
          </Col>
          <Col>
            <img src={require("../../images/tajweed.jpg")} alt="image1"/>
            <br/>
            <h5 style={{marginLeft: 10, fontWeight: "bold"}}>Tajweed and Tarteel</h5>
            <br/>
            <ul>
              <li>Quran Reading with tajweed</li>
              <li>Memorization of Quran</li>
              <li>Advanced Level Quran Tajweed</li>
            </ul>
          </Col>
        </Row>
        <hr />
        <div>
          <strong>Learn Quran Online Courses and Classes - Quran for kids</strong>
          <p>
            Learn Quran Online with Tajweed Quran Courses For Kids over Skype by a professional tutors 10 Years experience. We have qualified Quran teachers for Classes. Quran for kids Free Trial, Learn on your convenient time from anywhere.
          </p>
        </div>
        <div>
          <strong>Online Quran Classes and Courses</strong>
          <p>
            Kids are very active and sharp in memorization of anything, including the Quran, as compared to adults. Therefore it is suggested that the kids should be enrolled in the online Quran courses, especially if they wish to memorize the Holy Quran so that they will get the maximum possible benefits from the facility of the learning Quran online.
          </p>
        </div>
        <div>
          <strong>Quran Classes with Expert online quran Tutors</strong>
          <p>
            We have expert Quran tutors and teachers who are well equipped with the skills that make them adopt a unique method with every particular student. Our tutors are well aware of the needs of their students and open to explore new ways of teaching styles.
          </p>
        </div>
        </div>
      </div>
    );
  }
};

export default Quote;
