import React from "react";

const NotFound = () => {
  return (
    <h1 style={{ textAlign: "center", marginTop: "50px", fontWeight: "bold" }}>
      Page not found <br />
      error 404 :(
    </h1>
  );
};
export default NotFound;
